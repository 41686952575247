<template>
  <v-card flat color="transparent">
    <v-card-title class="text-h5">Şifre Doğrula</v-card-title>
    <v-card-subtitle>Giriş yapmak için kısa mesaj ile gönderilen yeni şifrenizi kullanabilirsiniz.</v-card-subtitle>
    <v-card-text>
      <v-row dense>
        <v-col cols="12">
          <v-otp-input length="6" v-model="approveForm.smsCode" type="number" ref="smsCode"></v-otp-input>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions>
      <v-btn class="text-capitalize rounded-lg" color="primary" block height="45" @click="validateSmsCode">
        Doğrula <span class="ml-2" v-if="smsResponse"> ({{ smsResponse.timeOutSecond }})</span>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { APPROVE, DECREASE_SECOND } from '@/store/modules/auth.module';

import { validationMixin } from 'vuelidate';
import validations from '../reset/_validations';

export default {
  name: 'Index',
  mixins: [validationMixin, validations],
  components: {},
  data() {
    return {
      approveForm: {
        smsCode: null,
      },
    };
  },
  computed: {
    ...mapGetters(['smsResponse', 'mobilePhone']),
  },
  created() {
    if (this.smsResponse !== null) {
      this.$nextTick(() => {
        const interval = setInterval(() => {
          this.decreaseSecond();
          if (this.smsResponse === null) {
            clearInterval(interval);
            this.$router.push({ name: 'forget' });
          }
        }, 1000);
      });
    } else {
      this.$router.push({ name: 'forget' });
    }
  },
  methods: {
    ...mapActions({
      approveReset: APPROVE,
    }),
    ...mapMutations({
      decreaseSecond: DECREASE_SECOND,
    }),
    validateSmsCode() {
      const payload = {
        key: this.mobilePhone,
        smsCode: this.approveForm.smsCode,
        isResetPassword: true,
      };
      this.approveReset(payload).then(() => {
        this.$router.replace({ name: 'garage' });
      });
    },
  },
};
</script>

<style></style>
